.actions-buttons-area {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;
    align-content: stretch;
}

.action-button {
    margin-right: 5px;
}

.page-navigation-nav {
	color: #51be78;
}

.icon-table {
	display: table !important;
}

.page-size {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
	padding-top: 9px;
}

.page-size > label {
	padding-left: 10px;
	padding-right: 10px;
	color: #51be78;
}

.select-input {
	padding-bottom: 5px;
}

.btn-add-client {
	margin-top: 20px;
}
