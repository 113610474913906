.validation-msg {
    color: red;
}

.recaptcha-area {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    margin-top: 10px;
}

.section__title__login{
    font-size: 50px;
    font-weight: 700;
    color: #fff;
}